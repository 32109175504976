import { Component, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as AOS from 'aos';
import { Article } from './models/article';
import { ArticleService } from './models/article.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'rg-consultoria';

  constructor(private articleService: ArticleService,private route: ActivatedRoute, private router: Router) {
  }

  articles: Article[];

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {
    if(window.pageYOffset>=300){
      document.getElementById("header").style.opacity="0";
      document.getElementById("goup").style.opacity="1";
    } else{
      document.getElementById("header").style.opacity="1";
      document.getElementById("goup").style.opacity="0";
    }
  }

  ngOnInit() {
    AOS.init();
  }

  goUp() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  goToLink(url: string){
    window.open(url, "_blank");
}

goTo(value){
  this.router.navigate([value, {}]);
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
}

}
