<div class="rg-block1">
    <div class="block">
        <div class="info">
            <div  data-aos="fade-up" data-aos-once="false" data-aos-duration="1000" data-aos-delay="1500">Conhecimento</div>
        </div>
        <div class="info" style="color: #1a4c9a;">
            <div  data-aos="fade-up" data-aos-once="false" data-aos-duration="1000" data-aos-delay="2000">que transforma</div>
        </div>
        <div class="info">
            <div></div>
        </div>
    </div>
</div>