import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Article } from '../models/article';
import { ArticleService } from '../models/article.service';

@Component({
  selector: 'app-noticia-detalhe',
  templateUrl: './noticia-detalhe.component.html',
  styleUrls: ['./noticia-detalhe.component.scss']
})
export class NoticiaDetalheComponent implements OnInit, OnDestroy {
  id: number;
  private sub: any;
  private loading: boolean;
  private noticias : Article[];
  private error : string;
  public selectedArticle: Article;

  constructor(private articleService: ArticleService,private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.id = +params['id'];
      this.loading = true;
      this.getCars();


   });

  }


  getCars(): void {

    this.articleService.getAll().subscribe(
      (res: Article[]) => {
        this.noticias = res;
        this.selectedArticle = this.noticias.find(x => x.id == this.id);
        this.loading=false;
      },
      (err) => {
        this.error = err;
        this.loading=false;
      }
    );
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
