import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Article } from './article';
import { MailCorpo } from './mail';


@Injectable({
  providedIn: 'root'
})
export class ArticleService {
  baseUrl = 'https://rgconsultoriaemsaude.com.br/api/';
articles: Article[];

constructor(private http: HttpClient) { }

  getAll(): Observable<Article[]> {
    return this.http.get(`${this.baseUrl}list`).pipe(
      map((res) => {
        this.articles = res['data'];
        return this.articles;
    }),
    catchError(this.handleError));
  }

  store(article: Article): Observable<Article[]> {
    return this.http.post(`${this.baseUrl}store`, { data: article })
      .pipe(map((res) => {
        this.articles.push(res['data']);
        return this.articles;
      }),
      catchError(this.handleError));
  }

  update(article: Article): Observable<Article[]> {
    return this.http.put(`${this.baseUrl}update`, { data: article })
      .pipe(map((res) => {
        const theArticle = this.articles.find((item) => {
          return +item['id'] === +article['id'];
        });
        if (theArticle) {
          theArticle['titulo'] = article['titulo'];
          theArticle['corpo'] = article['corpo'];
          theArticle['imgurl'] = article['imgurl'];
        }
        return this.articles;
      }),
      catchError(this.handleError));
  }

  delete(id: number,pass: string): Observable<Article[]> {
    var params = new HttpParams()
      .set('id', id.toString())
      .set('pass', pass.toString());

    return this.http.delete(`${this.baseUrl}delete`, { params: params })
      .pipe(map(res => {
        const filteredArticles = this.articles.filter((article) => {
          return +article['id'] !== +id;
        });
        return this.articles = filteredArticles;
      }),
      catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    console.log(error);
    // return an observable with a user friendly message
    return throwError('Error! something went wrong.');
  }

  uploadFile(file_data:FormData)
    {
      this.http.post(`${this.baseUrl}upload_img`,file_data)
      .subscribe(res => {
      }, (err) => {
      //send error response
    });
    }

    postMessage(mailCorpo: MailCorpo): Observable<boolean> {
      return this.http.post(`${this.baseUrl}mail`, { data: mailCorpo })
        .pipe(map((res) => {
          return true;
        }),
        catchError(this.handleError));
    }
    
}
