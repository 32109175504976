import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Article } from 'src/app/models/article';
import { ArticleService } from 'src/app/models/article.service';

@Component({
  selector: 'app-noticias',
  templateUrl: './noticias.component.html',
  styleUrls: ['./noticias.component.scss']
})
export class NoticiasComponent implements OnInit {

  constructor(private articleService: ArticleService,private route: ActivatedRoute, private router: Router) { }

  public noticias: Article[];
  public error:string;

  ngOnInit(): void {
    this.noticias = [];
    this.getCars();

  }

  getAdjusted(value:string){
    var tamanho = value.length;
    var adjustedString = value;
    if(tamanho>137){
      adjustedString = value.substr(0,137)
    }
    return adjustedString+"..."
  }

  getCars(): void {

    this.articleService.getAll().subscribe(
      (res: Article[]) => {
        this.noticias = res.reverse();
      },
      (err) => {
        this.error = err;
      }
    );
  }

  goToProductDetails(id) {
    this.router.navigate(['/noticia-detalhe', id]);
  }


}


