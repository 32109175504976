import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router) { }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {
    //console.log(window.pageYOffset);
    var element = document.getElementById("nossoscoceitos");
    var bodyRect = document.body.getBoundingClientRect(),
      elemRect = element.getBoundingClientRect(),
      offset = elemRect.bottom - bodyRect.bottom;

    var element = document.getElementById("img");
    var elemRect = element.getBoundingClientRect();
    var elemOffset = elemRect.bottom - bodyRect.bottom-150;
    console.log(elemOffset);
    if (window.pageYOffset >= elemOffset) {
      document.getElementById("img").style.animationName="example";
    } else {
    }

    var element = document.getElementById("nossoscoceitos");
    var elemRect = element.getBoundingClientRect();
    var elemOffset = elemRect.bottom - bodyRect.bottom;
    if (window.pageYOffset >= elemOffset) {
      document.getElementById("nossoscoceitos").style.opacity = "1";
      document.getElementById("nossoscoceitos").style.transform = "translatex(0)";
    } else {
    }

    var element = document.getElementById("ov1");
    var elemRect = element.getBoundingClientRect();
    var elemOffset = elemRect.bottom - bodyRect.bottom;
    if (window.pageYOffset >= elemOffset) {
      document.getElementById("ov1").style.marginLeft = "250px";
      document.getElementById("ov2").style.marginLeft = "250px";
      document.getElementById("ov1").style.width = "0px";
      document.getElementById("ov2").style.width = "0px";
    } else {
    }
    var element = document.getElementById("quemsomos");
    var elemRect = element.getBoundingClientRect();
    var elemOffset = elemRect.bottom - bodyRect.bottom;
    if (window.pageYOffset >= elemOffset) {
      document.getElementById("quemsomos").style.opacity = "1";
      document.getElementById("quemsomos").style.transform = "translatex(0)";
    } else {
    }
    var element = document.getElementById("sobrenos");
    var elemRect = element.getBoundingClientRect();
    var elemOffset = elemRect.bottom - bodyRect.bottom;
    if (window.pageYOffset >= elemOffset) {
      document.getElementById("sobrenos").style.opacity = "1";
      document.getElementById("sobrenos").style.transform = "translatex(0)";
    } else {
    }

    var element = document.getElementById("p1");
    var elemRect = element.getBoundingClientRect();
    var elemOffset = elemRect.bottom - bodyRect.bottom;
    if (window.pageYOffset >= elemOffset) {
      document.getElementById("p1").style.opacity = "1";
      document.getElementById("p1").style.transform = "translatex(0)";
    } else {
    }
    // var element = document.getElementById("p2");
    // var elemRect = element.getBoundingClientRect();
    // var elemOffset = elemRect.bottom - bodyRect.bottom;
    // if (window.pageYOffset >= elemOffset) {
    //   document.getElementById("p2").style.opacity = "1";
    //   document.getElementById("p2").style.transform = "translatex(0)";
    // } else {
    // }
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }

  goTo(value) {
    this.router.navigate([value, {}]);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  open(value){
    var element:Element;
    var element2:Element;
    element = document.getElementById('block'+value);
    element2 = document.getElementById('button'+value);
    if(element.classList.contains("active")){
      element.classList.remove("active");
      element2.classList.remove("active");
    }else{
      element.classList.add("active");
      element2.classList.add("active");
    }
  }

  ngOnInit(): void {
    //$('.carousel').carousel();
  }


}
