<div class="v-separator" style="margin-top: 50px;">
    <div class="v1">
    </div>
    <div class="v2">
    </div>
</div>
<div class="title-rg">
    <div data-aos="fade-right" data-aos-offset="200" data-aos-delay="50" data-aos-duration="1000"
        data-aos-easing="ease-in-out" data-aos-once="true">
        CONSULTORIA
    </div>
</div>
<div class="v-separator">
    <div class="v1">
    </div>
    <div class="v2">
    </div>
</div>



<div class="parallax-consultoria" style="margin-top: 90px;"></div>


<div class="text-block-rg" style="margin-top: 120px;">

    <div class="text-block">
        Com maior ênfase no valor sobre o volume, a reforma da assistência médica, juntamente com o avanço tecnológico,
        está mudando rapidamente a face do sistema de saúde do Brasil. À medida que novas abordagens para reembolso de
        prestadores e prestação de serviços de saúde são propostas e, em última instância, implementadas, os
        fornecedores e pagadores precisam de análises sofisticadas para entender o que funciona e o que não funciona e
        os dados para fundamentar as decisões políticas. A RG Consultoria em Saúde fornece uma variedade de tais
        serviços, adapta-os para atender às necessidades de cada cliente e os ajusta à medida que as necessidades mudam.
    </div>

    <div class="title-block" style="margin-top: 45px;">
        Como funciona nossa consultoria?
    </div>

    <div class="text-block" style="margin-top: 45px;">
        Antes de tudo, buscamos entender, por meio de uma reunião formal, qual a demanda do cliente. Mediante a
        solicitação, realizamos um diagnóstico institucional visando avaliar as fragilidades organizacionais que possam
        prejudicar o desempenho e as potencialidades que podem alavancar a performance do serviço. Frente a um
        resultado, partimos para as estratégias abaixo.
    </div>

    <div class="title-block" style="margin-top: 45px;">
        Planejamento Estratégico
    </div>

    <div class="text-block" style="margin-top: 45px;">
        Para executar e cumprir com um negócio, faz-se necessário a elaboração de estratégias com objetivo de chegar a
        algum lugar, alcançar alguma meta. Com vistas a isto, temos a expertise em auxiliar as organizações a elaborar e
        executar o planejamento estratégico. Como consequência, direcionando aos cumprimentos de sua missão e visão de
        futuro.
    </div>

    <div class="text-block from-right" style="margin-top: 45px;" id="b1">
        Quais os benefícios da elaboração e cumprimento de um planejamento estratégico?

        <ul>
            <li>Maior longevidade do negócio;</li>
            <li>Conhecimento das oportunidades e ameaças do mercado;</li>
            <li>Maior produtividade;</li>
            <li>Assertividade nas ações;</li>
            <li>Maior conhecimento sobre seu negócio;</li>
            <li>Monitoramento de forma tangível da performance do empreendimento.</li>
        </ul>
    </div>

    <div class="title-block" style="margin-top: 70px;">
        Assuntos Regulatórios
    </div>

    <div class="text-block" style="margin-top: 20px;">
        Os estabelecimentos sujeitos às normas de vigilância sanitária devem requerer a Licença Sanitária (LS) junto aos
        órgãos reguladores (VISA/ANVISA) antes de iniciarem suas atividades, pois exercê-las sem os mencionados
        documentos configura infração sanitária, estando o estabelecimento e seus responsáveis sujeitos às penalidades
        previstas na lei. Estas normas aplicam-se às farmácias, drogarias, laboratórios, hospitais, clínicas médicas e
        odontológicas, lojas de artigos médico-hospitalares, restaurantes, padarias, bares, lanchonetes, empórios, salão
        de embelezamento e estética, SPA, lojas de conveniência, entre outros. A RG Consultoria em Saúde, neste
        contexto, auxilia o estabelecimento, através de orientações, em como adequar-se e receber a visita do órgão
        regulador, bem como solucionar os apontamentos feitos durante vistoria sanitária.
    </div>

    <div class="title-block" style="margin-top: 70px;">
        Acreditação em Saúde
    </div>

    <div class="text-block" style="margin-top: 20px;">
        A acreditação em saúde é constituída de um sistema de avaliação e certificação de qualidade de serviços de saúde
        através de um processo voluntário, periódico e reservado. Trata-se de um processo de avaliação de instituições
        prestadoras de serviços na área de saúde para verificação do cumprimento de requisitos criados para aperfeiçoar
        a segurança e qualidade no cuidado. Esse processo busca estimular uma melhoria contínua e sustentada dos
        processos nas instituições de saúde, através do emprego de padrões e de metas nacionais e internacionais de
        segurança do paciente.
    </div>

    <div class="text-block from-right" style="margin-top: 45px;" id="b2">
        Quais os benefícios de se ter uma empresa acreditada?

        <ul>
            <li>Negociação de reajustes diferenciados junto aos convênios frente ao “Fator Qualidade”, determinado pela
                Agência Nacional de Saúde Suplementar;</li>
            <li>Processos definidos e conhecidos por todos;</li>
            <li>Garantia de Segurança do Paciente;</li>
            <li>Melhora do processo assistencial;</li>
            <li>Redução de custos e desperdícios;</li>
            <li>Melhor credibilidade pelos clientes.</li>
        </ul>
    </div>

    <div class="title-block" style="margin-top: 70px;">
        Credenciamento de Convênios
    </div>

    <div class="text-block" style="margin-top: 20px;">
        Atualmente, está cada vez mais difícil manter e reter clientes nas clínicas onde os atendimentos são apenas na
        modalidade particular. As pessoas têm mostrado grande preocupação com sua saúde, investindo no pagamento mensal
        de planos para terem o conforto de serem atendidas em diversas áreas e instituições de saúde. Além de mais
        econômico, elas conseguem se fidelizar a um serviço, já que o mesmo estará sempre disponível e acessível ao seu
        plano.
    </div>

    <div class="text-block" style="margin-top: 20px;">
        Então, para não cair em crise, é necessário se credenciar, trazendo para sua clinica o conforto e possibilidade
        de aumento da clientela, e como consequência, aumento do faturamento geral da instituição.
    </div>

    <div class="text-block" style="margin-top: 20px;">
        A RG Consultoria oferece a consultoria de credenciamento de clínicas, uma vez que o processo além de cansativo é
        extremamente burocrático, indo desde a pesquisa de qual convenio será o mais adequado até toda documentação
        exigida por cada um deles.
    </div>

    <div class="text-block" style="margin-top: 20px;">
        Dessa forma nós cuidamos do burocrático enquanto a instituição cuida dos atendimentos.
    </div>

    <div class="text-block from-right" style="margin-top: 45px; margin-bottom: 215px;" id="b3">
        Como vantagens de credenciar-se aos convênios tem-se:

        <ul>
            <li>Garantia de clientes frequentando o consultório;</li>
            <li>Aumento do faturamento mensal;</li>
            <li>Qualidade na prestação do serviço;</li>
            <li>Referenciamento de terceiros frente ao atendimento da clínica.</li>
        </ul>
    </div>

</div>