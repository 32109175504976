import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-rg-header',
  templateUrl: './rg-header.component.html',
  styleUrls: ['./rg-header.component.scss']
})
export class RgHeaderComponent implements OnInit {

  private _headerActive;

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
  }

  goTo(value){
    this.router.navigate([value, {}]);
    document.getElementById("header").classList.remove("active");
    this._headerActive = !this._headerActive;
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  clickedHeader(){
    if(!this._headerActive){
      console.log("active");
      document.getElementById("header").classList.add("active");
    }else{
      console.log("inactive");
      document.getElementById("header").classList.remove("active");
    }
    this._headerActive = !this._headerActive;
  }

  goToLink(url: string){
    window.open(url, "_blank");
}

}
