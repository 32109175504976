import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RgHeaderComponent } from './components/rg-header/rg-header.component';
import { RgBlock1Component } from './components/rg-block1/rg-block1.component';
import { RgBlock2Component } from './components/rg-block2/rg-block2.component';
import { ConsultoriaComponent } from './components/consultoria/consultoria.component';
import { InicioComponent } from './components/inicio/inicio.component';
import { ProgramasComponent } from './components/programas/programas.component';
import { ContatoComponent } from './components/contato/contato.component';
import { NoticiasComponent } from './components/noticias/noticias.component';

import {MatInputModule} from '@angular/material/input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClientModule } from '@angular/common/http';
import { CadastroNoticiasComponent } from './components/cadastro-noticias/cadastro-noticias.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';

import {MatSnackBarModule} from '@angular/material/snack-bar';
import { NoticiaDetalheComponent } from './noticia-detalhe/noticia-detalhe.component';

import { CarouselModule } from 'ngx-bootstrap/carousel';

@NgModule({
  declarations: [
    AppComponent,
    RgHeaderComponent,
    RgBlock1Component,
    RgBlock2Component,
    ConsultoriaComponent,
    InicioComponent,
    ProgramasComponent,
    ContatoComponent,
    NoticiasComponent,
    CadastroNoticiasComponent,
    NoticiaDetalheComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatInputModule,
    HttpClientModule,
    FormsModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    CarouselModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
