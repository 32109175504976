
<app-rg-header></app-rg-header>


<router-outlet></router-outlet>


<div (click)="goToLink('https://api.whatsapp.com/send?phone=5531999661825&text=Ol%C3%A1,%20vim%20atrav%C3%A9s%20do%20site,%20gostaria%20de%20saber%20mais%20sobre%20seus%20servi%C3%A7os%3F')" class="whats-link">
  <img src="../assets/images/whatsapp.svg">
</div>

<div id="goup" class="goup-link" (click)="goUp()">
  <svg preserveAspectRatio="xMidYMid meet" data-bbox="20 20 160 160" xmlns="http://www.w3.org/2000/svg" viewBox="20 20 160 160" height="100%" width="100%" data-type="color" role="img"><defs><style>#BACK_TO_TOP_BUTTON svg [data-color="1"] {fill: #FFFFFF;}
    #BACK_TO_TOP_BUTTON svg [data-color="2"] {fill: #111111;}
    #BACK_TO_TOP_BUTTON svg [data-color="3"] {fill: #F6F3EF;}</style></defs>
        <g fill-rule="evenodd">
            <path fill-rule="nonzero" fill="#FFFFFF" d="M180 100c0 44.183-35.817 80-80 80s-80-35.817-80-80 35.817-80 80-80 80 35.817 80 80z" data-color="1"></path>
            <path fill="black" d="M66.066 116L62 111.64 100.151 74 138 111.668 133.953 116l-33.825-32.3L66.066 116z" data-color="2"></path>
            <path fill-rule="nonzero" fill="lightgrey" d="M100 24c-41.974 0-76 34.026-76 76s34.026 76 76 76 76-34.026 76-76-34.026-76-76-76zm0-4c44.183 0 80 35.817 80 80s-35.817 80-80 80-80-35.817-80-80 35.817-80 80-80z" data-color="3"></path>
        </g>
    </svg>
</div>


<div class="other-links">
    <div class="block1">
      <div class="rg-blockFull">
        <div class="block">
            <div class="info" (click)="goTo('/inicio')">
                Início
            </div>
            <div class="info" (click)="goTo('/consultoria')">
                Consultoria
            </div>
            <div class="info" (click)="goTo('/programas')">
                Treinamentos
            </div>
            <div class="info"  (click)="goTo('/contato')">
              Contato
          </div>
          <div class="info" (click)="goTo('/noticias')">
            News
        </div>
        </div>
    </div>
    </div>
  </div>
  
  
  <div class="rg-footer">
  <div style="padding-top: 20px;">
    RG Consultoria em Saúde
  </div>
  <div>
    (31) 3654-1909 / (31) 99966-1825
  </div>
  <div>
    Av. Amazonas, 311 - Sala 703 - Centro, Belo Horizonte - MG, 30180-001
  </div>
  </div>