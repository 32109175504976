import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ArticleService } from 'src/app/models/article.service';
import { MailCorpo } from 'src/app/models/mail';

@Component({
  selector: 'app-contato',
  templateUrl: './contato.component.html',
  styleUrls: ['./contato.component.scss']
})
export class ContatoComponent implements OnInit {

  FormData: FormGroup;

  constructor(private builder: FormBuilder, private articleService: ArticleService, private _snackBar: MatSnackBar) { }

  ngOnInit(): void {

    this.FormData = this.builder.group({
      Fullname: new FormControl('', [Validators.required]),
      Surname: new FormControl('', [Validators.required]),
      Telephone: new FormControl('', [Validators.required]),
      Email: new FormControl('', [Validators.compose([Validators.required, Validators.email])]),
      Comment: new FormControl('', [Validators.required])
    });

  }

  enviar() {
    this.FormData.controls['Fullname'].markAsTouched();
    this.FormData.controls['Surname'].markAsTouched();
    this.FormData.controls['Telephone'].markAsTouched();
    this.FormData.controls['Email'].markAsTouched();
    this.FormData.controls['Comment'].markAsTouched();


    if (this.FormData.valid) {
      var mail = new MailCorpo(
      this.FormData.controls['Fullname'].value,
      this.FormData.controls['Surname'].value,
      this.FormData.controls['Email'].value,
      this.FormData.controls['Telephone'].value,
      this.FormData.controls['Comment'].value
      );


      this.articleService.postMessage(mail)
      .subscribe(response => {
        this._snackBar.open('E-mail Enviado com sucesso!', '', {
          duration: 3000,
          horizontalPosition: "center",
          verticalPosition: "bottom",
        });
        this.FormData.reset();
        
        ;
      }, error => {
        this._snackBar.open('Erro ao enviar o e-mail!, tente novamente mais tarde.', '', {
          duration: 3000,
          horizontalPosition: "center",
          verticalPosition: "bottom",
        });
      })

      
    } else {
      this._snackBar.open('Favor preencher todos os campos.', '', {
        duration: 3000,
        horizontalPosition: "center",
        verticalPosition: "bottom",
      });
    }
  }


  onSubmit(FormData) {
    this.articleService.postMessage(FormData)
      .subscribe(response => {
        console.log(response)
      }, error => {
        console.log({ error })
      })
  }

}
