import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-consultoria',
  templateUrl: './consultoria.component.html',
  styleUrls: ['./consultoria.component.scss']
})
export class ConsultoriaComponent implements OnInit {

  constructor() { }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {
    if(window.pageYOffset>=1400){
      document.getElementById("b1").style.opacity="1";
      document.getElementById("b1").style.transform = "translatex(0)";
    } else{
    }
    if(window.pageYOffset>=2200){
      document.getElementById("b2").style.opacity="1";
      document.getElementById("b2").style.transform = "translatex(0)";
    } else{
    }
    if(window.pageYOffset>=2800){
      document.getElementById("b3").style.opacity="1";
      document.getElementById("b3").style.transform = "translatex(0)";
    } else{
    }
  }

  ngOnInit(): void {
  }

}
