
<div>
  <form class="password-form">
    <mat-form-field>
      <mat-label>Senha</mat-label>
      <input matInput [(ngModel)]="pass" name="pass">
    </mat-form-field>
  </form>
</div>


<div *ngFor="let noticia of articles; let i = index">
    <form class="example-form">
        <mat-form-field>
            <mat-label>Título</mat-label>
            <input matInput [(ngModel)]="noticia.titulo" name="titulo">
          </mat-form-field>

          <br>

          <mat-form-field>
            <mat-label>Conteúdo</mat-label>
            <textarea matInput [(ngModel)]="noticia.corpo" name="corpo"></textarea>
          </mat-form-field>
          <br>

          <button (click)="atualizar(noticia)">Atualizar</button>
          <button (click)="deletar(noticia.id)">Deletar</button>
    </form>

    <input [formControl]="file" 
 (change)="fileChange(noticia,$event)" 
 type="file" placeholder="File">

    <div>
        __________________________________________________
    </div>
</div>

<form class="example-form2">
    <mat-form-field>
        <mat-label>Título</mat-label>
        <input matInput [(ngModel)]="newArticle.titulo" name="titulo">
      </mat-form-field>

      <br>

      <mat-form-field>
        <mat-label>Conteúdo</mat-label>
        <textarea matInput [(ngModel)]="newArticle.corpo" name="corpo"></textarea>
      </mat-form-field>

      <br>

      <button (click)="novo()">Novo</button>
</form>