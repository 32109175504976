import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CadastroNoticiasComponent } from './components/cadastro-noticias/cadastro-noticias.component';
import { ConsultoriaComponent } from './components/consultoria/consultoria.component';
import { ContatoComponent } from './components/contato/contato.component';
import { InicioComponent } from './components/inicio/inicio.component';
import { NoticiasComponent } from './components/noticias/noticias.component';
import { ProgramasComponent } from './components/programas/programas.component';
import { NoticiaDetalheComponent } from './noticia-detalhe/noticia-detalhe.component';


const routes: Routes = [
  { path: '', component: InicioComponent },
  { path: 'form', component: InicioComponent },
  { path: 'inicio', component: InicioComponent },
  { path: 'consultoria', component: ConsultoriaComponent },
  { path: 'programas', component: ProgramasComponent },
  { path: 'contato', component: ContatoComponent },
  { path: 'noticias', component: NoticiasComponent },
  { path: 'cadastro', component: CadastroNoticiasComponent },
  { path: 'noticia-detalhe/:id', component: NoticiaDetalheComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
