import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rg-block1',
  templateUrl: './rg-block1.component.html',
  styleUrls: ['./rg-block1.component.scss']
})
export class RgBlock1Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
