<div *ngIf="selectedArticle" class="news-block-detail" style="margin-top: 30px;">
    
    <div class="new-sub text-sub">
        <div class="title">
            {{selectedArticle.titulo}}
        </div>
        <div class="new-sub img-sub">
            <img style="width: 100%; height: 100%;" [src]="'https://rgconsultoriaemsaude.com.br/api/images/'+selectedArticle.imgurl">
        </div>
        <div class="content">
            <div style="white-space: break-spaces;">{{selectedArticle.corpo}}</div>
        </div>
    </div>
</div>