import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Article } from 'src/app/models/article';
import { ArticleService } from 'src/app/models/article.service';

@Component({
  selector: 'app-cadastro-noticias',
  templateUrl: './cadastro-noticias.component.html',
  styleUrls: ['./cadastro-noticias.component.scss']
})
export class CadastroNoticiasComponent implements OnInit {

  articles: Article[];
  newArticle: Article;
  error:string;
  file=new FormControl('');
  file_data:any='';
  pass:string;
  
  constructor(private articleService: ArticleService) { }

  ngOnInit(): void {
    this.newArticle = new Article();
    this.newArticle.titulo ="";
    this.newArticle.corpo ="";
    this.newArticle.corpo ="";
    this.getCars();
  }

  getCars(): void {

    this.articleService.getAll().subscribe(
      (res: Article[]) => {
        this.articles = res;
      },
      (err) => {
        this.error = err;
      }
    );
  }

  atualizar(noticia){
    noticia.pass = this.pass;
    this.articleService.update(noticia).subscribe(
      (res) => {
      },
      (err) => {
        this.error = err;
      }
    );
  }
  deletar(value:number){
    this.articleService.delete(value,this.pass).subscribe(
      (res) => {
        this.articles = res;
      },
      (err) => {
        this.error = err;
      }
    );


  }

  novo(){
    this.newArticle.pass = this.pass;
    this.articleService.store(this.newArticle).subscribe(
      (res) => {
        this.newArticle = new Article();
        this.newArticle.titulo ="";
        this.newArticle.corpo ="";
      },
      (err) => {
        this.error = err;
      }
    );
  }

  fileChange(noticia,event) {
    noticia.pass = this.pass;
    const fileList: FileList = event.target.files;
    //check whether file is selected or not
    if (fileList.length > 0) {

        const file = fileList[0];
        //get file information such as name, size and type
        //console.log('finfo',file.name,file.size,file.type);
        //max file size is 4 mb
        if((file.size/1048576)<=4)
        {
          let formData = new FormData();
          //let info={id:index,name:'raja'}
          formData.append('file', file, file.name);
          formData.append('id',noticia.id.toString());
          formData.append('tz',new Date().toISOString())
          formData.append('update','2')
          //formData.append('info',JSON.stringify(info))
          this.file_data=formData
          this.articleService.uploadFile(this.file_data);
          noticia.imgurl=noticia.id.toString()+"."+this.adjustImgFormat(file.type);
          this.articleService.update(noticia).subscribe(
            (res) => {
              this.newArticle = new Article();
              this.newArticle.titulo ="";
              this.newArticle.corpo ="";
            },
            (err) => {
              this.error = err;
            }
          );
        }else{
          //this.snackBar.open('File size exceeds 4 MB. Please choose less than 4 MB','',{duration: 2000});
        }
        
    }

  }

  adjustImgFormat(value){
    
    var adjusted="";

    adjusted = value.toString().split('/').pop()

    if(adjusted=="jpeg"){
      return "jpg"
    }

    return adjusted;
  }

}
