import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-programas',
  templateUrl: './programas.component.html',
  styleUrls: ['./programas.component.scss']
})
export class ProgramasComponent implements OnInit {

  constructor() { }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {
    if (window.pageYOffset >= 800) {
      document.getElementById("b1").style.opacity = "1";
      document.getElementById("b1").style.transform = "translatex(0)";
    } else {
    }
    if (window.pageYOffset >= 2100) {
      document.getElementById("b2").style.opacity = "1";
      document.getElementById("b2").style.transform = "translatex(0)";
    } else {
    }
    if (window.pageYOffset >= 2900) {
      document.getElementById("b3").style.opacity = "1";
      document.getElementById("b3").style.transform = "translatex(0)";
    } else {
    }
    if (window.pageYOffset >= 4000) {
      document.getElementById("b4").style.opacity = "1";
      document.getElementById("b4").style.transform = "translatex(0)";
    } else {
    }
  }

  ngOnInit(): void {
  }
  scroll(value) {
    const yOffset = -190;
    const element = document.getElementById(value);
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: 'smooth' });

  }
}
