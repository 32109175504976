<div class="rg-header-normal">

    <div class="first-line-header">
        <div class="left-box-header">
            <img style="width: 130px; cursor: pointer;" (click)="goTo('/inicio')" src="../../../assets/images/logo-nova-fundo-trans-768x768.png">
        </div>
        <div class="middle-box-header">
            <div (click)="goTo('/inicio')" class="header-option">
                Início
            </div>
            <div (click)="goTo('/consultoria')" class="header-option">
                Consultoria
            </div>
            <div (click)="goTo('/programas')" class="header-option">
                Programas e Treinamentos
            </div>
            <div (click)="goTo('/contato')" class="header-option">
                Contato
            </div>
            <div (click)="goTo('/noticias')" class="header-option">
                News
            </div>
        </div>
        <div class="right-box-header">
            <div (click)="goToLink('https://www.instagram.com/rg_consultoria_/')" class="icon-header">
                <img src="../../../assets/images/icone-instagram.png">
            </div>
            <div (click)="goToLink('https://br.linkedin.com/in/rg-consultoria-em-sa%C3%BAde-92057a185')" class="icon-header">
                <img width="20px" src="../../../assets/images/iconmonstr-linkedin-3-240.png">
            </div>
            <div (click)="goToLink('https://twitter.com/consultoria_rg')" class="icon-header">
                <img src="../../../assets/images/icone-twitter.png">
            </div>
            <div (click)="goToLink('https://www.facebook.com/RG-Consultoria-em-Sa%C3%BAde-441059409988315')" class="icon-header">
                <img width="20px" src="../../../assets/images/facebook.png">
            </div>
        </div>
    </div>


</div>

<div class="white-space">

</div>

<div id="header" class="rg-header-mobile">

    <div class="first-line-header">
        <div class="left-box-header">
            <div (click)="goToLink('https://www.instagram.com/rg_consultoria_/')" class="icon-header">
                <img src="../../../assets/images/icone-instagram.png">
            </div>
            <div (click)="goToLink('https://br.linkedin.com/in/rg-consultoria-em-sa%C3%BAde-92057a185')" class="icon-header">
                <img width="20px" src="../../../assets/images/iconmonstr-linkedin-3-240.png">
            </div>
            <div (click)="goToLink('https://twitter.com/consultoria_rg')" class="icon-header">
                <img src="../../../assets/images/icone-twitter.png">
            </div>
            <div (click)="goToLink('https://www.facebook.com/RG-Consultoria-em-Sa%C3%BAde-441059409988315')" class="icon-header">
                <img width="20px" src="../../../assets/images/facebook.png">
            </div>
        </div>
        <div class="right-box-header">
            <div class="box" (click)="clickedHeader()">
                <div class="line e1">
                    
                </div>
                <div class="line e2">
                    
                </div>
                <div class="line e3">
                    
                </div>
            </div>
        </div>
    </div>

    <div class="second-line-header">
        <div (click)="goTo('/inicio')" class="header-option">
            Início
        </div>
        <div (click)="goTo('/consultoria')" class="header-option">
            Consultoria
        </div>
        <div (click)="goTo('/programas')" class="header-option">
            Programas e Treinamentos
        </div>
        <div (click)="goTo('/contato')" class="header-option">
            Contato
        </div>
        <div (click)="goTo('/noticias')" class="header-option">
            News
        </div>
    </div>

    <div class="line-separator">

    </div>
    <div class="line-separator">

    </div>

    <div class="middle-box-set">
        <img src="../../../assets/images/logo-nova-fundo-trans-768x768.png">
    </div>

    
</div>