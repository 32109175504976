<div class="rg-image-block" style="margin-top: 70px;">
    <div class="block">
        <div class="tag c1">
        </div>
        <div class="tag">
            <div class="tag-info style1">Programas</div>
            <div class="tag-info style2">MENTORIA PARA ENFERMEIROS</div>
            <div><div class="separator"></div></div>
            <div class="tag-info style3" (click)="scroll('medicos')">Mais informações</div>
        </div>
        <img style="width: 100%; height: 100%;" src="../../../assets/images/img-block1.png">
    </div>
    <div class="block">
        <div class="tag c2">
        </div>
        <div class="tag">
            <div class="tag-info style1">Treinamento</div>
            <div class="tag-info style2">EXPERIÊNCIA DO PACIENTE</div>
            <div><div class="separator"></div></div>
            <div class="tag-info style3" (click)="scroll('pacientes')">Mais informações</div>
        </div>
        <img style="width: 100%; height: 100%;" src="../../../assets/images/img-block2.png">
    </div>
</div>

<div class="rg-image-block">
    <div class="block">
        <div class="tag c3">
        </div>
        <div class="tag">
            <div class="tag-info style1">Treinamento</div>
            <div class="tag-info style2">ATENDIMENTO EXEMPLAR</div>
            <div><div class="separator"></div></div>
            <div class="tag-info style3" (click)="scroll('atendimento')">Mais informações</div>
        </div>
        <img style="width: 100%; height: 100%;" src="../../../assets/images/img-block3.png">
    </div>
    <div class="block">
        <div class="tag c4">
        </div>
        <div class="tag">
            <div class="tag-info style1">Treinamento</div>
            <div class="tag-info style2">LIDERANÇA EM ENFERMAGEM</div>
            <div><div class="separator"></div></div>
            <div class="tag-info style3" (click)="scroll('lideranca')">Mais informações</div>
        </div>
        <img style="width: 100%; height: 100%;" src="../../../assets/images/img-block4.png">
    </div>
</div>

<div class="text-block-rg" style="margin-top: 200px;">

    <div id="medicos" class="title-block">
        Programa - Mentoria para Enfermeiros
    </div>

    <div class="img-block from-right" style="margin-top: 65px;" id="b1">
        <img style="width: 100%;" src="../../../assets/images/img-medicos.png">
    </div>

    <div class="text-block" style="margin-top: 20px;">
        Ninguém aprende sozinho, ninguém chega lá sem aprender, ninguém chega lá sozinho. Nós acreditamos nisto!
    </div>

    <div class="text-block" style="margin-top: 20px;">
        Você saiu ou está saindo da faculdade e compartilhou seu caminho e aprendeu muita coisa com seus professores,
        amigos e colegas de classe. Porém, agora, ao imergir no mercado de trabalho tudo é diferente, nem sempre temos
        com quem contar, pessoas para nos orientar e nos auxiliar a aperfeiçoar nossos conhecimentos, dividindo alegrias
        e os desafios nesta jornada vasta.
    </div>

    <div class="text-block" style="margin-top: 20px;">
        A mentoria da RG Consultoria é um programa de suporte e desenvolvimento onde profissionais diferenciados (anos
        de formação e conhecimentos) se reúnem em grupos para trocarem experiências e aprofundar em assuntos relevantes
        para seu crescimento como profissional Enfermeiro.
    </div>

    <div class="text-block" style="margin-top: 20px;">
        Os grupos de mentoria se reúnem uma vez por semana e em cada momento, os mentores encorajam os profissionais a
        refletirem sobre sua profissão e sobre si mesmo, buscando atingir o autoconhecimento. Ainda, é discutido sobre
        profissionalismo, escolhas futuras, além de trabalhar com a estimulação sobre as práticas da enfermagem
        adequadas e atualizadas e o raciocínio clínico.
    </div>

    <div class="text-block" style="margin-top: 20px;">
        Através de roda de conversa, vínculos se formam e a mentoria proporciona aos profissionais um senso de
        comunidade, em um ambiente de acolhimento e aprendizagem mútua. A diversidade de pessoas que participam do
        programa e compartilham suas ideias e experiências, sejam elas mentores ou profissionais participantes do
        programa, representam a maior força do modelo de mentoria da RG Consultoria em Saúde.
    </div>
    <div class="text-block" style="margin-top: 20px;">
        <p>Público Alvo: Acadêmicos de enfermagem (a partir do 8º período) e enfermeiros formados (turmas distintas).
        </p>

        <p>Método: Encontros remotas em plataforma segura, de forma dinâmica e interativa.</p>

        <p>Carga horaria: 300 horas</p>

        <p>Duração: 06 meses</p>

        <p>Periodicidade dos encontros: 01 hora 30 min./semana</p>
    </div>
    <div class="text-block" style="margin-top: 45px;">
        Módulos Abordados:

        <ul>
            <li>Módulo 01: Práticas Básicas de Enfermagem</li>
            <li>Módulo 02: Práticas Avançadas de Enfermagem</li>
            <li>Módulo 03: Práticas de Urgência e Emergência</li>
            <li>Módulo 04: Gestão de Pessoas e Carreira</li>
            <li>Módulo 05: Gestão Financeira</li>
            <li>Módulo 06: Gestão de Marketing e Personal Branding</li>
            <li>Módulo 07: Gestão de Processos</li>
        </ul>

    </div>

</div>

<div class="text-block-rg" style="margin-top: 50px;">

    <div id="pacientes" class="title-block">
        Treinamento - Experiência do Paciente
    </div>

    <div class="img-block from-right" style="margin-top: 65px;" id="b2">
        <img style="width: 100%;" src="../../../assets/images/img-pacientes.png">
    </div>

    <div class="text-block" style="margin-top: 20px;">
        A Experiência do Paciente é um contemporâneo capítulo que trata da humanização dos cuidados de saúde. Trata-se
        da soma de todas as interações, moldadas pela cultura da organização, que influenciam a percepção do paciente
        através da continuidade do cuidado. Está ligado a forma como o cliente/paciente o que vê, o que ouve e o que
        sente ao receber cuidados. Já se sabe que as condições do ambiente de cuidado, a empatia dos profissionais das
        organizações de saúde, o cuidado centrado no paciente, influenciam no processo de cura. Ou seja, ambiente
        adequado, atencioso e seguro, cuidado centrado e reconfortante, transmissão de informações que auxilie na tomada
        de decisão consciente sobre os cuidados, tratamento honesto, respeitoso e digno são elementos que sustentam uma
        experiência positiva do paciente.
    </div>

    <div class="text-block" style="margin-top: 20px;">
        <p>Público Alvo: Profissionais da área de saúde.</p>
        <p>Método: Encontro remoto em plataforma segura, de forma dinâmica e interativa.</p>
        <p>Carga horaria: 8 horas.</p>
    </div>
    <div class="text-block" style="margin-top: 45px;">
        Módulos Abordados:
        <ul>
            <li>Módulo 1: Introdução a Experiência do Paciente</li>
            <li>Módulo 2: Pilares básicos como alicerce a Experiência do Paciente</li>
            <li>Módulo 3: Engajamento do Paciente e Familiar</li>
            <li>Módulo 4: Engajamento da Organização e Colaboradores</li>
        </ul>
    </div>

</div>


<div id="atendimento" class="text-block-rg" style="margin-top: 50px;">

    <div class="title-block">
        Treinamento - Atendimento Exemplar
    </div>

    <div style="width: 100%;" class="img-block from-right" style="margin-top: 65px;" id="b3">
        <img style="width: 100%;" src="../../../assets/images/img-atendimento.png">
    </div>

    <div class="text-block" style="margin-top: 20px;">
        O alcance da satisfação do cliente começa pelo atendimento impecável desde seu primeiro contato com a
        instituição.
    </div>

    <div class="text-block" style="margin-top: 20px;">
        O esforço para se alcançar a satisfação do cliente tem sido uma busca incansável, colocando nas mãos dos
        indivíduos da linha de frente a responsabilidade do encantamento ao cliente. Mesmo com todo avanço tecnológico a
        melhor opção para se atender um ser humano continua sendo outro ser humano, com capacidade de entender suas
        dores e resolver seus problemas.
    </div>

    <div class="text-block" style="margin-top: 20px;">
        ​

        O treinamento Atendimento Exemplar da RG Consultoria é uma capacitação aos profissionais da linha de frente das
        instituições sendo eles secretariados, gerentes de atendimento, recepcionistas, que terão a oportunidade de se
        aprimorarem e oferecer o seu melhor em cada atendimento prestado.
    </div>

    <div class="text-block" style="margin-top: 20px;">
        O treinamento será ministrado por profissionais da saúde que possuem expertise e tato com cliente, que já
        estiveram na linha de frente no atendimento e conseguem desenvolver essa melhora junto aos alunos.
    </div>

    <div class="text-block" style="margin-top: 20px;">
        Serão ministradas aulas remotas em grupos, com conteúdo diferenciado e dinâmico, fazendo o profissional imergir
        no curso, simulações realísticas também serão realizadas afim de que, na prática, o profissional possa melhorar
        suas estratégias, bem como demonstrar seus conhecimentos adquiridos no curso, durante as trocas de experiências
        e muitas estratégias serão discutidas afim de se agregar mais conhecimento.

        Adentrando nessa capacitação sua instituição e você só tem a ganhar.
    </div>

    <div class="text-block" style="margin-top: 20px;">
        <p>Público Alvo: Secretárias, recepcionistas, gerentes de atendimento.</p>
        <p>Método: Encontros remotos em plataforma segura, de forma dinâmica e interativa.</p>
        <p>Carga horaria: 8 horas.</p>
    </div>
    <div class="text-block" style="margin-top: 45px;">
        Módulos Abordados:
        <ul>
            <li>Módulo 1: Atendimento ao Cliente Exemplar</li>
            <li>Módulo 2: Satisfação do Ciente</li>
            <li>Módulo 3: Tipos de Clientes (Interno e Externo)</li>
            <li>Módulo 4: Resolvendo Problemas</li>
            <li>Módulo 5: Gerenciando o atendimento via redes sociais</li>
            <li>Módulo 6: Lidando com reclamações e queixas</li>
            <li>Módulo 7: Atendimento telefônico personalizado</li>
        </ul>
    </div>

</div>

<div id="lideranca" class="text-block-rg" style="margin-top: 50px;">

    <div class="title-block">
        Treinamento - Liderança em Enfermagem
    </div>

    <div class="img-block from-right" style="margin-top: 65px;" id="b4">
        <img style="width: 100%;" src="../../../assets/images/img-stet.png">
    </div>

    <div class="text-block" style="margin-top: 20px;">
        Liderança é considerada a habilidade de motivar, inspirar, influenciar e comandar um grupo de pessoas a fim de
        atingir os objetivos institucionais. A liderança pode ser ainda mais complexa, uma vez que, além de todas estas
        habilidades, necessitará de trabalhar a sua inteligência emocional para gerir os seus funcionários/seguidores e
        a equipe. Além disto, o líder deve ser capaz de desenvolver seus liderados atendendo as expectativas pessoais e
        profissionais, com a perspectiva da instituição.
    </div>

    <div class="text-block" style="margin-top: 20px;">
        <p>Público Alvo: Acadêmicos de enfermagem e enfermeiros.</p>
        <p>Método: Encontros remotos em plataforma segura, de forma dinâmica e interativa.</p>
        <p>Carga horaria: 8 horas.</p>
    </div>
    <div class="text-block" style="margin-top: 45px; margin-bottom: 50px">
        Módulos Abordados:
        <ul>
            <li>Módulo 1: História e teorias sobre a liderança</li>
            <li>Módulo 2: As competências da liderança</li>
            <li>Módulo 3: Liderança e sucessão</li>
        </ul>
    </div>

</div>