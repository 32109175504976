<div class="v-separator" style="margin-top: 50px;">
    <div class="v1">
    </div>
    <div class="v2">
    </div>
</div>
<div class="title-rg">
    <div data-aos="fade-right" data-aos-once="false" data-aos-offset="200" data-aos-duration="1500">News</div>
</div>
<div class="v-separator">
    <div class="v1">
    </div>
    <div class="v2">
    </div>
</div>

<div *ngFor="let noticia of noticias">
    <div class="news-block" style="margin-top: 30px;">
        <div class="new-sub">
            <img style="width: 100%; height: 100%;max-height: 310px;" [src]="'https://rgconsultoriaemsaude.com.br/api/images/'+noticia.imgurl">
        </div>
        <div class="new-sub text-sub" (click)="goToProductDetails(noticia.id)">
            <div class="title">
                {{noticia.titulo}}
            </div>
            <div class="content">
                {{getAdjusted(noticia.corpo)}}
            </div>
        </div>
    </div>
</div>