export class MailCorpo {

    constructor(nome,sobrenome,mail,numero,corpo){
        this.nome=nome;
        this.sobrenome = sobrenome;
        this.mail = mail;
        this.numero = numero;
        this.corpo = corpo;
    }

    nome:string;
    sobrenome:string;
    mail:string;
    numero:string;
    corpo:string;
    }