<div class="contact-block">

    <div class="main-block">

        <div class="header-block">
            <div data-testid="svgRoot-comp-kh3xhdpw2" class="svg_style"><svg preserveAspectRatio="none"
                    data-bbox="20 20 160 160" viewBox="20 20 160 160" height="200" width="200"
                    xmlns="http://www.w3.org/2000/svg" data-type="shape" role="img">
                    <g>
                        <path d="M180 126.583V73.417h-53.417V20H73.417v53.417H20v53.166h53.417V180h53.166v-53.417H180z">
                        </path>
                    </g>
                </svg>
            </div>
            <div class="style1">Fale conosco</div>
            <div class="style2">Preencha o formulário abaixo para entrar em contato com a gente.</div>
        </div>


        <form  [formGroup]="FormData" style="margin-top: 30px;" class="example-form">
            <div class="input-block">
                <div class="input-line">
                    <div class="input-style">
                        <!-- <mat-form-field style="width: 75%;">
                            <mat-label>Nome</mat-label>
                            <input matInput placeholder="Nome"  formControlName="Fullname">
                        </mat-form-field> -->
                        <label class="label-doc">Nome<span style="color: red;">*</span></label>
                        <mat-form-field appearance="outline" style="width: 90%;">
                            <input matInput placeholder="Nome" formControlName="Fullname">
                            <!-- <mat-hint>Hint</mat-hint> -->
                        </mat-form-field>

                    </div>
                    <div class="input-style">
                        <!-- <mat-form-field style="width: 75%;">
                            <mat-label>Sobrenome</mat-label>
                            <input matInput placeholder="Sobrenome">
                        </mat-form-field> -->
                        <label class="label-doc">Sobrenome<span style="color: red;">*</span></label>
                        <mat-form-field appearance="outline" style="width: 90%;">
                            <input matInput placeholder="Sobrenome" formControlName="Surname">
                            <!-- <mat-hint>Hint</mat-hint> -->
                        </mat-form-field>

                    </div>
                </div>
                <div class="input-line">
                    <div class="input-style">
                        <!-- <mat-form-field style="width: 75%;">
                            <mat-label>Endereço de email</mat-label>
                            <input matInput placeholder="Insira um Endereço de email válido" formControlName="Email">
                        </mat-form-field> -->
                        <label class="label-doc">Endereço de email<span style="color: red;">*</span></label>
                        <mat-form-field appearance="outline" style="width: 90%;">
                            <input matInput placeholder="Endereço de email" formControlName="Email">
                            <!-- <mat-hint>Hint</mat-hint> -->
                        </mat-form-field>

                    </div>



                    <div class="input-style">
                        <label class="label-doc">Insira um número<span style="color: red;">*</span></label>
                        <mat-form-field appearance="outline" style="width: 90%;">
                            <input matInput placeholder="Insira um número" formControlName="Telephone">
                            <!-- <mat-hint>Hint</mat-hint> -->
                        </mat-form-field>

                    </div>
                </div>
                <div class="input-line">
                    <textarea placeholder="Escreva sua mensagem" class="textarea-style" formControlName="Comment">
                        </textarea>
                </div>

                <div class="input-line" style="margin-top: 50px;">
                    <div class="button-style" (click)="enviar()">Enviar</div>
                    <!-- <button type="submit" class="btn btn-primary" [disabled]="!FormData.valid">Submit</button> -->
                </div>

            </div>
        </form>


        <!-- <div class="container">
            <h2 class="text-center mb-5">Angular Contact Form Hosted On FireBase</h2>
            <div class="row">
                <div class="col-md-6 offset-md-3">
                    <form [formGroup]="FormData" (ngSubmit)="onSubmit(FormData.value)">

                        <div class="form-group">
                            <label for="fullname">Nome</label>
                            <input type="text" class="form-control" name="Fullname" placeholder="Nome" formControlName="Fullname">
                        </div>

                        <div class="form-group">
                            <label for="Email">Email</label>
                            <input type="email" class="form-control" name="Email" aria-describedby="emailHelp"
                                placeholder="Enter email" formControlName="Email">
                        </div>
                        <div class="form-group">
                            <label for="comment">Comentário</label>
                            <textarea class="form-control" formControlName="Comment" name="Comment">
                            </textarea>
                        </div>
                        <button type="submit" class="btn btn-primary" [disabled]="!FormData.valid">Submit</button>
                    </form>
                </div>
            </div>
        </div> -->

    </div>

</div>