
<div class="main-banner-container">
  <div class="banner-overlay"></div>
  <div class="text-container">
    <div class="style1">Quem somos</div>
    <div class="style2">Empresa de Consultoria</div>
    <div class="style3">e Treinamentos em Saúde</div>
    <div class="style4">Atendemos todo território nacional</div>
    <div class="button-reveal">
      <div class="style5" (click)="goTo('/programas')">Conheça nossos serviços</div>
    </div>
    
  </div>
</div>

 <app-rg-block1></app-rg-block1>
<!-- <app-rg-block2></app-rg-block2> -->


<div class="rg-block2">
  <div id="img" class="img"></div>
</div>

<!-- <div class="v-separator2">
  <div class="v1">
  </div>
  <div class="v2">
  </div>
</div> -->

<div class="v-separator" style="margin-top: 100px;">
  <div class="v1">
  </div>
  <div class="v2">
  </div>
</div>
<div class="title-rg">
  <div class="from-left" id="nossoscoceitos">NOSSO CONCEITOS</div>
</div>
<div class="v-separator">
  <div class="v1">
  </div>
  <div class="v2">
  </div>
</div>


<div class="text-block-rg" style="margin-top: 50px;">
  <div class="title-block">
    Conhecimento que transforma
  </div>

  <div class="text-block">
    Nós acreditamos que o conhecimento nasce através da invenção e reinvenção, por meio de um questionamento,
    inquietação ou impaciência de homens do mundo. O conhecimento é um processo que transforma aquilo que se conhece e
    também o conhecedor. Ou seja, o conhecimento emerge pela relação dialógica e recíproca entre um trinômio formado
    pelo conhecimento, facilitador e o discípulo.
  </div>


  <div class="button-bg" (click)="goTo('/programas')">
    <div id="ov1" class="overbutton1"></div>Programas e Treinamentos
  </div>
</div>

<div class="text-block-rg" style="margin-top: 61px;">
  <div class="title-block">
    Criatividade para inovar
  </div>

  <div class="text-block">
    A criatividade é uma das competências que permite o desenvolvimento de ideias inovadoras ou incrementais. O desafio
    de evoluir como profissional ou como empresa faz parte do dia a dia de empreendedores. Mediante a demanda de nossos
    clientes, somos facilitadores e transformadores que viabilizam modelo inovador de gestão de negócios na área da
    saúde.
  </div>
  <div class="button-bg" (click)="goTo('/consultoria')">
    <div id="ov2" class="overbutton1"></div>Consultoria
  </div>
</div>

<div class="v-separator" style="margin-top: 150px;">
  <div class="v1">
  </div>
  <div class="v2">
  </div>
</div>
<div class="title-rg">
  <div class="from-left" id="quemsomos">
    Quem somos
  </div>
</div>
<div class="v-separator">
  <div class="v1">
  </div>
  <div class="v2">
  </div>
</div>

<div class="text-block-rg" style="margin-top: 50px;">

  <div class="title-block">
    A Empresa
  </div>

  <div class="text-block">
    A RG Consultoria em Saúde nasceu com uma visão diferenciada e inovadora, com grandes habilidades na área da Saúde.
    Ela surge com o desafio de apoiar o desenvolvimento das Instituições de Saúde e de profissionais por meio de
    treinamentos, consultoria em gestão de clínica e na intervenção e monitoramento de assuntos regulatórios.
  </div>

  <div class="text-block">
    Buscando sempre atender às necessidades específicas do cliente, nós oferecemos diversos serviços, desde o
    planejamento para abertura da sua primeira clínica até a expansão dos negócios.
  </div>

  <div class="text-block"  style="margin-bottom: 30px;">
    O nosso cliente sempre será nossa prioridade e, por isso, buscamos sempre atender às suas expectativas. Nesse
    sentido, valorizamos as pessoas e, principalmente, os nossos colaboradores, que são parte fundamental para que este
    processo aconteça com excelência.
  </div>

<carousel [isAnimated]="true">
  <slide>
    <img src="../../../assets/images/messages-info.jpg" alt="first slide" style="display: block; width: 100%;">
    <div class="carousel-caption d-md-block ad-low">
      <h1 class="carousel-text">Missão</h1>
      <p class="carousel-text">Desenvolver soluções em saúde para a maturidade organizacional e profissional.</p>
    </div>
  </slide>
  <slide>
    <img src="../../../assets/images/messages-info.jpg" alt="first slide" style="display: block; width: 100%;">
    <div class="carousel-caption d-md-block ad-low">
      <h1 class="carousel-text">Visão</h1>
      <p class="carousel-text">Ser referência em desenvolvimento de soluções em saúde.</p>
    </div>
  </slide>
  <slide>
    <img src="../../../assets/images/messages-info.jpg" alt="first slide" style="display: block; width: 100%;">
    <div class="carousel-caption d-md-block ad-low">
      <h1 class="carousel-text">Valores</h1>
      <p class="carousel-text">Ética, Transparência, Disciplina, Sustentabilidade e Resolutividade.</p>
    </div>
  </slide>
</carousel>

<div class="v-separator" style="margin-top: 150px;">
  <div class="v1">
  </div>
  <div class="v2">
  </div>
</div>
<div class="title-rg">
  <div class="from-left" id="sobrenos">
    Sobre Nós
  </div>
</div>
<div class="v-separator">
  <div class="v1">
  </div>
  <div class="v2">
  </div>
</div>

<div class="text-block-rg" style="margin-top: 50px;">

  <div class="text-block">
    Como líderes experientes, inovadores e reconhecidos em seus campos, nossa equipe de consultoria é formada por
    executivos de sucesso e jovens talentos. Cada um de nossos consultores traz vasto conhecimento clínico, gerencial e
    tecnológico em saúde. Investimos em nossas pessoas para obter e manter certificações profissionais reconhecidas nas
    Instituições e exigidas pelos nossos clientes.
  </div>

  <div class="title-block" style="margin-top: 50px;">
    Liderança
  </div>

</div>


<div class="profile-block" id="block1" style="margin-top: 50px;">

  <div class="picture-and-text-box">
    <div class="picture from-left" id="p1">
      <img src="../../../assets/images/img-rodrigo.png">
    </div>
    <div class="texts">
      <div class="name">
        RODRIGO GOMES
      </div>
      <div class="job">
        Diretor Executivo
      </div>
    </div>

  </div>
  <div class="button" id="button1" (click)="open(1);">
    <svg class="point" style="width:50px;height:50px" viewBox="0 0 24 24">
      <path fill="currentColor"
          d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" />
  </svg>
  </div>

  <div class="parag" style="margin-top: 25px;">
    Rodrigo tem uma carreira distinta em seus doze anos de formação como enfermeiro, coordenador, docente universitário, auditor e gestor de sistemas de saúde.
  </div>
  <div class="parag" style="margin-top: 25px;">
    Atua como consultor e especialista em planejamento estratégico, redesenho de processos de negócios, avaliação de programas, medição de desempenho e gerenciamento de qualidade, mudança organizacional, desenvolvimento de liderança, análise de dados e informações de saúde.
  </div>
  <div class="parag" style="margin-top: 25px;">
    Durante sua carreira, atuou em hospitais referência no estado de Minas Gerais, em funções de liderança. Foi Coordenador da Central de Materiais Esterilizados, Enfermeiro Gestor assistencial e de autorizações de procedimentos de alta complexidade, além de Auditor de contas hospitalares do Convênio Intermédica Saúde.
  </div>
  <div class="parag" style="margin-top: 25px;">
    Foi Responsável Técnico no Hospital 25 de Maio e Referência Técnica da Rede de Urgência e Emergência do Município de Esmeraldas, além de responsável pelo gerenciamento técnico de uma rede de diagnóstico por imagem constada na grande BH.
  </div>
  <div class="parag" style="margin-top: 25px;">
    Hoje possui vínculo com a multinacional DNV-GL sediada em Høvik, na Noruega, a qual presta serviços de certificação na norma ONA, é docente em um grande centro universitário de Belo Horizonte e é Gerente de Qualidade em um grande Hospital também na região.
  </div>
  <div class="parag" style="margin-top: 25px;">
    Rodrigo é formado em Enfermagem, recebeu título de especialista pela Universidade Federal Fluminense em Micropolíticas de Gestão e Trabalho em Saúde, pela Universidade de Brasília em Saúde Pública, pela Universidade Mackenzie em Gestão Estratégica de Negócios e Segurança do Paciente.
  </div>
  <div class="parag" style="margin-top: 25px;">
    É Mestre em Gestão e Inovação, Doutorando em Administração. Possui certificação internacional pelo IRCA como Auditor Líder ISO 9001:2015 e ISO 13485:2016 e é membro do Colégio Brasileiro de Executivos em Saúde.
  </div>

</div>

<!-- <div class="profile-block active" id="block2" style="margin-top: 25px;">

  <div class="picture-and-text-box">
    <div class="picture from-left" id="p2">
      <img src="../assets/images/img-vanderleia.png">
    </div>
    <div class="texts">
      <div class="name">
        VANDERLÉIA VIEIRA
      </div>
      <div class="job">
        Gerente de Processos
      </div>
    </div>

  </div>

  <div class="parag" style="margin-top: 25px;">
    Nossa Gerente de Processos Vanderléia é Enfermeira graduada pela Faculdade de Minas, trabalhou cinco anos em um
    Hospital Geral de Grande porte tendo experiencia em pronto-socorro, reprodução humana, centro de terapia intensiva.
  </div>
  <div class="parag" style="margin-top: 25px;">
    Foi analista da qualidade em empresa referencia em esterilização de artigos médicos hospitalares. Trabalhou no maior
    laboratório de simulação realística de Minas Gerais da faculdade FAMINASBH. Atualmente é responsável técnica do
    bloco cirúrgico do Instituto Transplantare, sócia e consultora da RG Consultoria.
  </div>

</div> -->

<div class="insta-block">
  <div class="text-block">
    <div class="insta-text">
      SIGA A RG CONSULTORIA
    </div>
    <div class="insta-text">
      NO INSTAGRAM
    </div>
    <div (click)="goToLink('https://www.instagram.com/rg_consultoria_/')" style="margin-top: 38px;" class="link-text">
      @rg_consultoria_
    </div>
  </div>
</div>